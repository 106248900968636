@font-face {
    font-family: 'Gilroy-Light';
    src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Medium';
    src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
}


@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('./assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
}




* {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    font-family: 'Gilroy-Regular';
}