.HeaderWrapper {
    background: #FFFFFF;
    height: 100px;
    filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.03));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px 0px 60px;
    z-index: 20;
    position: sticky;
    top: 0;

    @media screen and (max-width: 500px) {
        flex-direction: row-reverse;
        padding: 0px 40px 0px 26px;
    }

    .sticky {
        background: #fff;
        -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        }
}

.Logo {
    height: 40px;

    @media screen and (max-width: 375px) {
    height: 32px;
    }
}

.NavMenuWrapper {
    display: flex;
    align-items: center;

    @media screen and (max-width: 900px) {
        display: none;
    }

    .NavLinks {
        text-decoration: none;
        padding-right: 30px;
        font-weight: 500;
        font-family: 'Gilroy-Medium';
        color: #222222;

        &:visited {
            color: #222222;
        }

        &:hover {
            color: #38A4FF;
        }

        &:active {
            color: #38A4FF;
        }
    }
}


.css-w5q9ds {
    border: none;
    background: none;
    color: #222222 !important;
    cursor: pointer;
    font-weight: 500 !important;
    font-family: 'Gilroy-Medium';
    padding-right: 28px !important;

    &:hover {
        border: none;
        color: #38A4FF !important;
    }

    &:active {
            color: #38A4FF !important;
        }

    &:focus {
        box-shadow: none !important;
    }
}

.DropdownMenu {
    text-decoration: none;
}


.css-1ozmk1d {
    width: 170px;
    min-width: 170px !important;
    padding: 10px 0px 10px 0px !important;
    box-shadow:  1px 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.css-13c7rae {
    background: none;
    border: none;
    cursor: pointer;
    color: #222222 !important;
    font-family: 'Gilroy-Medium';
    font-weight: 500 !important;
    font-size: 16px;
    margin-bottom: 2px;

     &:hover {
        border: none;
        color: #38A4FF !important;
    }

    &:active {
            color: #38A4FF !important;
            background: none !important;
            border: none !important;
        }

    &:focus {
        box-shadow: none !important;
    }
    
}

.MobileNavMenuWrapper {
    display: none;

    @media screen and (max-width: 900px) {
        display: block;
    }
}

.MenuGroupTitle {
    font-size: 16px !important;
    font-family: "Gilroy-Medium" !important;
    font-weight: 500 !important;
    padding-bottom: 5px !important;
    color: white !important;
}

.MenuItemOption {
    font-family: "Gilroy-Regular" !important;
    padding-bottom: 13px !important;
    color: rgba(255, 255, 255, 0.75) !important;
}

.OtherMenuItems {
    font-size: 16px !important;
    font-family: "Gilroy-Medium" !important;
    font-weight: 500 !important;
    padding-bottom: 20px !important;
    color: white !important;
}

.OtherMenuItemsCrypto {
    font-size: 16px !important;
    font-family: "Gilroy-Medium" !important;
    font-weight: 500 !important;
    padding-bottom: 20px !important;
    padding-top: 10px !important;
    color: white !important;
}

.css-6e3h0h {
    background: #222222 !important;
    width: 65% !important;
}

.css-1iqbypn {
    border-radius: 50% !important;
    border-color: none !important;
    top: 35px !important;
    right: 20px !important;

    &:active {
        border: none !important;
        box-shadow: none !important;
    }

    &:focus {
        border: none !important;
        box-shadow: none !important;
    }
}

.css-bb7nfz {
    width: 42px !important;
    display: none !important;

    &:focus {
        border: none !important;
        box-shadow: none !important;
    }

    @media screen and (max-width: 900px) {
        display: flex !important;
    }
}

// .css-hunt27 {
//     color: black !important;
// }

.css-79z5gx {
    padding-top: 78px !important;
}

.MenuFooterWrapper {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 25px !important;
}

.FooterMenuLogo {
    margin-bottom: 5px !important;
}

.FooterMenuNote {
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    font-family: 'Gilroy-Regular';
}

// .css-13c7rae {
    
// }