.BlackMarketPageWrapper {
    padding: 20px 70px 60px 70px;

    @media screen and (max-width: 740px) {
        padding: 20px 35px 60px 35px;
    }

    @media screen and (max-width: 500px) {
        padding: 20px 25px 50px 25px;
    }
}

.BlackMarketPageTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Gilroy-Medium';
    font-size: 35px;

    @media screen and (max-width: 768px) {
        font-size: 30px;
    }

    @media screen and (max-width: 500px) {
        font-size: 22px;
    }
}

.BmPriceContainerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Gilroy-SemiBold';
}

.CurrencyWrapper {
    display: flex;
    align-items: center;

    img {
        padding-right: 30px;
        height: 70px;

        @media screen and (max-width: 740px) {
        padding-right: 20px;
    }

    @media screen and (max-width: 500px) {
        height: 60px;
        padding-right: 12px;
    }

    @media screen and (max-width: 375px) {
        height: 50px;
        padding-right: 12px;
    }

}
}

.CurrencyName {
    font-size: 20px;

    @media screen and (max-width: 500px) {
        font-size: 16px;
    }

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}

.CurrencyPrice {
    font-size: 20px;

    @media screen and (max-width: 500px) {
        font-size: 16px;
    }

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }

    p:nth-child(1) {
        padding-bottom: 20px;

        @media screen and (max-width: 500px) {
        padding-bottom: 8px;
    }
    }
}