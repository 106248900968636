.FooterWrapper {
    background: #222222;
    height: 200px;
    color: white;
    padding: 10px 0px;

    @media screen and (max-width: 740px) {
        height: auto;
    }
}

.FooterLinksWrapper {
    display: flex;
    align-items: center;
    padding: 60px 50px 40px 60px;
    justify-content: space-between;

    @media screen and (max-width: 740px) {
        flex-direction: column;
        padding: 50px 50px 30px;

        .FooterLogo {
            margin-bottom: 22px;
            height: 45px;
        }
    }
}

.FooterTextLinks {
    display: flex;
    font-family: 'Gilroy-Regular';

    .FooterAbout {
        text-decoration: none;
        color: white;
    }

    .FooterContact {
        padding-right: 30px;
        text-decoration: none;
        color: white;
    }

    @media screen and (max-width: 740px) {
        margin-bottom: 25px;
    }
}

.FooterSocialIconWrapper {
    img {
        margin-right: 18px;

        &:hover {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 740px) {
        img:nth-child(3) {
            margin-right: 0px;
        }
    }
    
}

.FooterNote {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-family: 'Gilroy-Regular';

    @media screen and (max-width: 740px) {
        padding-bottom: 20px;
    }
}