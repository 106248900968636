.CryptoCurrencyPrice {
    padding-bottom: 0px;
    font-size: 20px;

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}

.CryptoCurrencyName {
    font-size: 20px;

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}

.CryptoCurrencySubName {
    color: rgba(34, 34, 34, 0.75);
    font-family: 'Gilroy-Regular';
    font-size: 20px;
}