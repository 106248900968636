.NewsDetailWrapper {
    padding: 20px 70px 60px 70px;
}

.NewsBackNav {
    margin-bottom: 40px;
    margin-top: 50px;
    font-family: 'Gilroy-Regular';
    font-size: 20px;
}

.NewsSubDetailsWrapper {
    font-family: "Gilroy-Regular";
    margin-bottom: 15px;
    color: rgba(34, 34, 34, 0.75);
    font-size: 18px;
;
}

.NewsTitle {
    font-family: 'Gilroy-Medium';
    font-size: 30px;
    color: #222222;
}


.NewsInDetail {
    font-family: "Gilroy-Regular";
    font-size: 20px;
    color: #333333;
}