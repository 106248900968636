.NewsPageWrapper {
    padding: 0px 0px 0px 10px;
}

.NewsPageTitle {
    margin-bottom: 40px;
    margin-top: 50px;
    font-family: 'Gilroy-Medium';
    font-size: 35px;
}

.NewsMainWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Gilroy-SemiBold';
}

.NewsWrapperContainer {
    display: flex;
    align-items: center;
}

.NewsSubDetailsWrapper {
    display: flex;
    color: rgba(34, 34, 34, 0.75);
    font-family: "Gilroy-Regular";
}

.NewsTitle {
    font-size: 10px;
    padding-bottom: 10px;
}

.NewsSource {
    padding-right: 4px;
}




