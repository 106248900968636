.BuySellTextWrapper {
    p {
        color: rgba(34, 34, 34, 0.75);
        font-size: 20px;
        font-family: "Gilroy-Medium";

        @media screen and (max-width: 500px) {
        font-size: 16px; 
    }

        @media screen and (max-width: 375px) {
            font-size: 14px;
    }
    }

    p:nth-child(1) {
        padding-bottom: 20px;

        @media screen and (max-width: 500px) {
        padding-bottom: 8px;
    }
    }
}