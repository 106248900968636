.PriceContainer {
    max-width: 1150px;
    background: #FFFFFF;
    filter: drop-shadow(6px 6px 12px rgba(0, 0, 0, 0.08));
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 30px 40px; 

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
        border-radius: 10px;
        margin-bottom: 22px;
    }
}


