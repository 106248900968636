.CbnPageWrapper {
    padding: 20px 70px 60px 70px;

    @media screen and (max-width: 500px) {
        padding: 20px 25px 50px 25px;
    }
}

.CbnPageTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Gilroy-Medium';
    font-size: 35px;

    @media screen and (max-width: 768px) {
        font-size: 30px;
    }

    @media screen and (max-width: 500px) {
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}