.AtmCurrencyWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.AtmCurrencyName {
    font-size: 20px;
    padding-bottom: 8px;

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}

.AtmCurrencyLastUpdate {
    font-family: "Gilroy-Regular";
    color: rgba(34, 34, 34, 0.75);
    font-size: 20px;

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}

.AtmCurrencySymbol {

    @media screen and (max-width: 375px) {
        font-size: 14px;
    }
}

.AtmCurrencyPrice {
    p {
        padding-bottom: 0; 
        font-size: 20px;

        @media screen and (max-width: 375px) {
            font-size: 14px;
        }
    }
}