.AboutPageWrapper {
    padding: 20px 70px 60px 70px;

    @media screen and (max-width: 500px) {
        padding: 20px 25px 100px 25px;
    }
}

.AboutPageTitle {
    margin-top: 20px;
    margin-bottom: 17px;
    font-family: 'Gilroy-Medium';
    font-size: 35px;

    @media screen and (max-width: 768px) {
        font-size: 30px; 
    }

    @media screen and (max-width: 500px) {
        font-size: 22px; 
    }
}

.AboutText {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Gilroy-Regular';

    @media screen and (max-width: 375px) {
        font-size: 16px;
        line-height: unset;
    }
}